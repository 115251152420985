
@media (max-width: 900px) {
    .profileContainer{
        margin: 0px;
    }
    .profileHead {
      display: block;
      line-height: 1;
      margin-bottom: 50px;
      text-align: center;

    }
    .profileHead_id {
        flex-direction: column;
        align-items: center;
    }
    .profileHead_names{
        align-items: center;
        margin-left: 0px;
        padding-top: 20px;
    }
    .profileHead_names_one{
        font-size: 30px
    }
    .daily_rate{
        text-align: center;
    }
    .about_edu{
        flex-direction: column;
    }
    .about,.edu{
        width: 100%;
    }
}  