.multi-select {
  background-color: #f4f4f4;
  border-radius: 20px !important;
}

.rmsc {
  --rmsc-main: none !important;
  --rmsc-hover: #c0c0d4!important;
  --rmsc-selected: #d2c9c9!important;
  --rmsc-border: none!important;
  --rmsc-gray: #555555!important;
  --rmsc-bg: #f4f4f4!important;
  --rmsc-p: 10px!important;
  --rmsc-checkboxes: none !important;
  --rmsc-radius: 20px!important;
  --rmsc-h: 3rem!important;
  font-size: 13px !important;
 font-weight: 500;

}

.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  border: none !important;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  text-align: left;
}

.dropdown-content .checkbox {
  display: none;
}
.react-multi-select-option:hover {
  background-color: red;
}

.input-container {
  position: relative;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  background-color: #f4f4f4;
  display: flex;
  padding: 0 5px;
  border: 0.4px solid rgba(118, 118, 118, 0.3);
  justify-content: space-around;
  align-items: center;
}

.input_style {
  width: 100%;
  height: 2.5rem;
  border-radius: 10px;
  background-color: #f4f4f4;
  border: 0;
  margin: 0.3rem 0;
  padding: 10px;
  cursor: pointer;
}
 
.my-input {
  width: 100%;
  height: 2.6rem;
  margin: auto 0;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-around;
  align-items: center;
  outline: none;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  border: 0;
  padding: 5px;
}

.input-container:hover {
  border-color: #000;
}

.MuiSelect-select.MuiSelect-select {
  text-align: left;
}

.input-container.active {
  border: 2px solid #d66233;
}

// .react-tel-input:hover {
//   border: 1px solid #000;
//   border-radius: 3px 8px 8px 3px;
// }

// .react-tel-input.active {
//   border: 1px solid #d66233;
//   border-radius: 3px 8px 8px 3px;
// }

.css-ha3bif-MuiTimelineItem-root:before {
  display: none;
}
.logo-image {
  max-width: 200px;
}
.css-13cymwt-control {
  border-radius: 16px !important;
  line-height: 2.1375em !important;
  font-size: 13px;
  font-family: 'Geologica', sans-serif;
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  color: #212b36;
  border: none;
  background-color: #f4f4f4;
}
#react-select-2-listbox {
  background-color: #ffffff;
  z-index: 1000;
  opacity: 1;
}

.css-6w5v88-MuiInputBase-root-MuiOutlinedInput-root {
  height: 2.5rem;
  font-size: 13px !important;
}
.css-1d4p9dq-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
}
.css-1lz3kgb-MuiTabs-indicator {
  display: none;
}
.css-1cenb80-MuiButtonBase-root-MuiTab-root {
  background-color: #ffffff;
}
.css-1cenb80-MuiButtonBase-root-MuiTab-root {
  background-color: #ffffff;
}
.css-1cenb80-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #ffffff !important;
  background-color: #d66233;
}
.css-j4g7h6-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff;
  background-color: #d66233;
}
.css-vf0j7o-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #ffffff;
}

.css-1d4p9dq-MuiPaper-root-MuiCard-root {
  background-color: #ffffff6c !important;
  border-radius: 32px;
}

.css-8ebtji-MuiTypography-root {
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #333333;
}

.css-cfjp6x-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none;
}
.css-cfjp6x-MuiInputBase-root-MuiInput-root {
  border-bottom: none;
}
.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 13px !important;
  padding: 10px 14px !important;
}
.css-v23o8z .ql-container.ql-snow {
  font-size: 13px !important;
}
.css-v1xcew .ql-container.ql-snow {
  font-size: 13px !important;
}
.css-1xqqk46-MuiInputBase-root-MuiInput-root:before {
  border-bottom-color: transparent;
}

.css-1xqqk46-MuiInputBase-root-MuiInput-root:before {
  border-bottom: transparent;
  border-bottom-color: transparent;
}

// main.js styles
::-webkit-scrollbar {
  height: 4px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  background-color: #686868;
  border: 1px solid #686868;
}

.main_style {
  /* background-image: url("/static/assets/home/dashboard.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;
  padding: 1rem 3rem;
  z-index: -2;
}

.main_links {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 40px;
  background-color: #FFFAF3;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10000;  
}
.main_links_2{
  display: flex;
  flex-direction: row;
   justify-content: space-between;
  align-items: center;
   
}

.main_btn {
  height: 40px;
  width: 120px;
  background: #d66233;
  border-radius: 6px;
  border: 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 141%;
  text-transform: uppercase;
  color: #ffffff;
  padding: 6px 4px;
  margin: 10px;
}

.main_btn_2 {
  height: 40px;
  width: 120px;
  background: transparent;
  border-radius: 6px;
  color: #d66233;
  border: 1px solid #d66233;  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 141%;
  text-transform: uppercase;
   padding: 6px 4px;
  margin: 10px;
}

.main_btn_outline {
  height: 50px;
  width: 153px;
  background: #d66233;
  border-radius: 16px;
  border: 0;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 141%;
  text-transform: uppercase;
  color: #ffffff;
  padding: 6px 1px;
}

.main_btn:hover {
  background: transparent;
  color: #d66233;
  border: 1px solid #d66233;
}

.main_btn_2:hover {
  background: transparent;
  color: #d66233;
  opacity: 0.5;
  border: 1px solid #d66233;
}

.main_text {
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-weight: 700;
  font-size: 45px;
  line-height: 39px;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: #404e57;
}

.main_images {
  display: flex;
  margin-bottom: 3rem;
  width: 92%;
  overflow-x: auto;
}

.main_img {
  width: 400px;
  height: 310px;
  text-align: left;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: flex-end;
  padding: 0 2rem;
  margin-right: 1rem;
}

.text {
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #fff;
  font-size: 18px;
  line-height: 20px;
  margin: 0 0 1rem 0;
}

.main_bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
}

.bottom1 {
  margin: auto 0;
}

.content_style {
  background: #faece7;
  padding: 0.2rem;
  min-height: 460px;
  display: flex;
   align-items: center;
}

.content_arrows {
  display: flex;
}

.arrow_con {
  width: 8%;
  margin-top: 1rem;
  margin-right: 2rem;
}

.arrow_container {
  display: flex;
  flex-direction: column;
  width: 28%;
  margin-right: 1rem;
}

.arrow_items {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.arrow_num {
  width: 50px;
  height: 50px;
  background: #d66233;
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.02em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.arrow {
  margin: auto;
  //width: 60%;
}

.content_text {
  width: 80%;
  text-align: left;
  margin-top: 1rem;
}

.text_header {
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #333333;
}

.text_content {
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #333333;
}
@media (max-width: 1050px) {
  .main_links img {
    width: 110px;
    height: 50px;
    max-width: 110px;
  }
}

@media (max-width: 650px) {
  .main_style {
    padding: 1rem;
    min-height: 60vh;
  }

  .main_btn {
    width: 22vw;
    height: 2.5rem;
    font-size: 14px;
    padding: 0;
  }

  .main_links img {
    width: 110px;
    height: 50px;
    max-width: 110px;

  }

  .main_text {
    font-size: 22px;
    width: 90%;
    line-height: 29px;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }

  .main_images {
    margin-bottom: 2rem;
    width: 90%;
  }

  .text {
    font-size: 14px;
    line-height: 15px;
  }

  .main_bottom {
    width: 100%;
    flex-direction: column-reverse;
  }

  .bottom1 {
    text-align: left;
  }

  .bottom1 img {
    width: 100px;
    height: 20px;
  }

  .bottom2 img {
    width: 50px;
  }

  .content_style {
    padding: 0;
    width: 100%;
  }

  .content_arrows {
    padding: 1rem 0;
    width: 75%;
    flex-direction: column;
  }

  .arrow_con {
    display: none;
  }

  .arrow {
    display: none;
  }

  .content_style {
    align-items: center;
    justify-content: center;
  }

  .arrow_items {
    align-items: center;
    justify-content: center;
  }

  .arrow_num {
    width: 40px;
    height: 40px;
  }

  .arrow_container {
    width: 100%;
    margin-right: 0;
  }

  .content_text {
    width: 100%;
    text-align: center;
  }

  .text_header {
    margin: 1rem 0;
  }

  .text_content {
    margin: 0.5rem 0 1rem 0;
  }
}

// Footer styles
/* footer */

.dpd_footer {
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  text-align: left;
}

.dpd_bottom {
  padding: 2rem 4rem;
  display: flex;
}

.footer_I {
  width: 20%;
  margin: 5px;
}

.footer_link {
  color: #ffffff;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 5px;
}

.f_content {
  width: 25%;
  margin: 0 1rem;
}

.f_contentSmall {
  width: 15%;
  margin: 0 1rem;
}

.cursor {
  cursor: pointer;
}

.f_contentHead {
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16.8492px;
  line-height: 203.9%;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #ffffff;
}

.f_contentText {
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 10.5308px;
  line-height: 203.9%;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #ffffff;
}

.line_footer {
  background-color: #807e7d;
  height: 0.1rem;
  display: block;
  width: 85%;
  margin: auto;
}

.copy_right {
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  padding: 1rem 4rem;
  font-style: normal;
  font-weight: 600;
  font-size: 11.3846px;
  line-height: 203.9%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #ffffff;
}

@media (max-width: 650px) {
  /* footer */
  .dpd_bottom {
    flex-direction: column;
    padding: 2rem;
  }

  .footer_I {
    width: 50%;
    margin-left: 1rem;
  }

  .footer_link {
    width: 100%;
    justify-content: flex-start;
  }

  .f_contentSmall {
    width: 40%;
  }

  .f_content {
    width: 40%;
  }

  .line_footer {
    width: 70%;
  }
}

// Details styles
.detail_cover {
  position: absolute;
  top: 15px;
  right: 0;
  width: 30%;
  min-height: 70vh;
  background: #e2c3c3;
  border-radius: 50px 0px 0px 60px;
  padding: 1.5rem 2rem;
  opacity: 10;
}

.detail_icon {
  display: flex;
  justify-content: right;
  margin-bottom: 1rem;
  cursor: pointer;
}

.detail_content {
  text-align: left;

  /* height: 100vh; */
  /* overflow-y: auto; */
}

.detail_content h3 {
  margin: 0.5rem 0;
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #d66233;
}

.detail_content p {
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #333333;
  margin: 0.2rem 0;
}

.detail_btn {
  background: transparent;
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 141%;
  text-transform: uppercase;
  cursor: pointer;
  color: #d66233;
  padding: 16px 0;
  gap: 10px;
  width: 216px;
  height: 55px;
  border: 1px solid #d66233;
  border-radius: 16px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.detail_btn:hover {
  background: #d66233;
  color: #fff;
}

@media (max-width: 650px) {
  .detail_cover {
    width: 65%;
    min-height: 40vh;
  }

  .detail_content img {
    width: 60%;
  }

  .detail_btn {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    height: 44px;
    font-size: 14px;
    line-height: 100%;
    width: 120px;
  }
}

// sub main styles
.section_cover {
  margin: 4rem 0;
  padding: 2rem;
}

.section_handler {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.section_div {
  width: 45%;
  margin: 1rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section_div h3 {
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 61px;
  letter-spacing: -0.02em;
  color: #d66233;
  margin: 0;
}

.section_div p {
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #333333;
  margin: 0;
}

.section_bg {
  margin: 1rem;
  background: #d9d9d9;
  border-radius: 40px;
  width: 45%;
  height: 250px;
}

/* .bottom_container {
  background-image: url("/assets/home/submain_bg.png");

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
} */

.bottom_cover {
  display: flex;
}

.bottom_header {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bottom_header h3 {
  font-family: 'Geologica', sans-serif;
  font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 61px;
  letter-spacing: -0.02em;
  color: #f2f2f2;
  width: 80%;
  margin: 0;
  margin-bottom: 1rem;
}

.bottom_icons {
  display: flex;
}

.line_icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
}

.line {
  width: 8px;
  height: 5px;
  background: #f2f2f2;
  border-radius: 20px;
  margin: 0 2px;
}

.activeLine {
  width: 20px;
  height: 5px;
  background: #f2f2f2;
  border-radius: 20px;
  margin: 0 2px;
}

.icons {
  display: flex;
  align-items: center;
}

.icon_left {
  width: 40px;
  height: 40px;
  background: rgba(238, 238, 238, 0.11);
  opacity: 0.9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.2em;
  cursor: pointer;
}

.icon_rite {
  width: 40px;
  height: 40px;
  background: #f2f2f2;
  border-radius: 16px;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.2em;
  cursor: pointer;
}

.bottom_text {
  text-align: left;
  background: rgba(192, 89, 46, 0.8);
  padding: 2rem;
  width: 52%;
  min-height: 300px;
}
 

.texts p {
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #f2f2f2;
}

.texts h3 {
  font-family: 'Geologica', sans-serif;
font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #f2f2f2;
  margin: 0;
}

@media (max-width: 650px) {
  .section_cover {
    margin: 2rem 0;
  }
  .section_handler {
    flex-direction: column;
    align-items: center;
  }
  .section_div {
    width: 80%;
    text-align: center;
  }

  .section_bg {
    width: 80%;
  }
  .bottom_cover {
    flex-direction: column;
  }
  .bottom_header {
    width: 80%;
    margin: 2rem;
  }
  .bottom_header h3 {
    font-size: 35px;
    line-height: 48px;
    text-align: center;
  }
  .bottom_icons {
    flex-direction: column-reverse;
    margin: 1rem 0;
  }
  .line_icons {
    margin: 1rem;
  }
  .icons {
    margin: 1rem;
  }
  .icon_rite {
    width: 30px;
    height: 30px;
    margin: 0 0.5rem;
  }
  .icon_left {
    width: 30px;
    height: 30px;
    margin: 0 0.5rem;
  }
  .bottom_text {
    width: 100%;
    text-align: center;
  }
  .comma {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}
.css-yjq3tk .ql-container.ql-snow {
  font-size: 13px !important;
}


.reactEasyCrop_Container {
  // width: 300px!important;
  height: 370px!important;
}


.react-datepicker-wrapper{
  width: 100%!important;
}


.css-t3i9iw-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
  min-height: 22px;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded{
  margin-bottom: 5px;
  margin-top: 0px;
}