



.input_form {
    width: 80%;
    height: 2.5rem;
    border-radius: 20px;
    background-color: #f4f4f4;
    border: 0;
    margin: 0.3rem 0;
    padding: 10px;
    cursor: pointer;
}